import React, { useState } from 'react';

async function fetchWithTimeout(resource, options = {}) {
  const { timeout = 3000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal
  });

  clearTimeout(id);

  return response;
}

export const App = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const onResult = (result) => {
    setResponse(result.message);
    setError(null);
  };

  const onError = (error) => {
    setResponse(null);
    setError(`Error ${new Date().toString()}`, error.message)
  };

  const makeRequest = () => {
    fetchWithTimeout("https://luderssoftware.com/health")
      .then(res => res.json())
      .then(onResult, onError)
  }

  return (
    <>
      <h1>Response</h1>
      <h3>{response || 'Empty'}</h3>

      <h1>Error</h1>
      <h3>{error || 'Empty'}</h3>

      <button onClick={makeRequest}>
        Make request
      </button>
    </>
  );
}
